import { Component, Renderer2 } from '@angular/core';
import { Router, Event, NavigationEnd, NavigationStart  } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Resultados de fútbol en vivo y partidos hoy en Marcadores247.com';

  constructor(
    private renderer: Renderer2,
    private readonly router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd || event instanceof NavigationStart) {
        if (!event.url.includes('matchtracker-live') && !event.url.includes('sports-live')) {
          if (!event.url.includes('match/details')) this.loadScriptTags()
          else if (event.url.includes('match/details')) this.loadScriptTagsDetail();
        }
      }
    });
  }

  loadScriptTags(): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = './assets/js/tags.js'; 
    script.id = 'clever-core';
    script.CDATA_SECTION_NODE;
    script.async = true;
    script.defer = false;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  loadScriptTagsDetail(): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = './assets/js/tags-clever.js'; 
    script.id = 'clever-core';
    script.CDATA_SECTION_NODE;
    script.async = false;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  
}
