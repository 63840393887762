export const environment = {
  production: false,
  urlSVG: './assets/svg/',
  urlIMG: './assets/img/',
  urlAudio: './assets/audio/',
  logo: 'logo-m247-color.png',
  logoHeader: 'logo-m247-color.png',
  // API: 'http://localhost:5050/api/',
  API: 'https://api-v2.marcadores247.com/api/',
  //API: 'https://api-m247.gimalca.com/api/',
  urlApp: 'http://uat.marcadores247.com/',
  urlWidgets: 'https://widgets.thesports01.com/'
};

