import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  readonly defaultLanguage = 'es';

  readonly languages = [
    {code: 'es', value: 'Spanish', flag: 'es-icon.png'},
    {code: 'en', value: 'English', flag: 'en-icon.png'}
  ];
 
  constructor(
    public translate: TranslateService
  ) {
    this.loadServices();
  }

  loadServices() {
    const language: any = localStorage.getItem('language');
    this.translate.setDefaultLang(language ? JSON.parse(language).lang : this.defaultLanguage);
  }

  setLanguage(lang: any) {
    localStorage.removeItem('language');
    localStorage.setItem('language', JSON.stringify({lang}));
    return true;
  }

  useLanguage(lang: any) {
    this.translate.use(lang ? lang : this.translate.getDefaultLang());
  }

  getCurrentLanguage() {
    const language: any = localStorage.getItem('language');
    return language ? JSON.parse(language).lang : this.defaultLanguage;
  }

  getDefaultLanguage() {
    return this.defaultLanguage;
  }

  
}
