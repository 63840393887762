import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslatorService } from './services/translator.service';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./components/layout/layout.module').then(
      m => m.LayoutModule
    )
  },
  {
    path: 'matchtracker-live/:sport/:id',
    loadChildren: () => import('./components/page-live-tracker/page-live-tracker.module').then(
      m => m.PageLiveTrackerModule
    )
  },
  {
    path: 'sports-live/:id/:filter',
    loadChildren: () => import('./components/page-load-sports/page-load-sports.module').then(
      m => m.PageLoadSportsModule
    )
  },
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      useHash: false}
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    translatorService: TranslatorService
  ) {}
}

